<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            {{ $t('global.today') }}
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :locale="$i18n.locale === 'th' ? 'th' : 'en'"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @change="updateRange"
          @click:date="handleSelect($event)"
          @click:day="handleSelect($event)"
        ></v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: {
      schedule: {
        type: Array,
        default: () => []
      },
      fulldate: {
        type: Array,
        default: () => []
      },
      available: {
        type: Array,
        default: () => []
      },
      event: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      focus: '',
      type: 'month',
      events: []
    }),
    computed: {
      min () {
        return new Date().toISOString()
      },
      fullLanguage () {
        return this.$t('global.full')
      }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      isTextValid (val) {
        if (val === '' || null) {
          return false
        }
        return true
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updateRange () {
        const events = []
        const full = this.fulldate
        // const { available } = this
        const { event } = this
        const available = this.schedule.filter((item) => item.isOpen && item.slot < item.max)

        if (event && event.length > 0) {
          const language = localStorage.getItem('language')
          event.map((item) => {
            const checkOpen = available.filter((items) => items.date === item.date)
            if (checkOpen.length > 0) {
              events.push({
                name: language === 'th' && this.isTextValid(item.title) ? item.title : item.titleEng,
                start: new Date(item.date),
                end: new Date(item.date),
                color: item.color
              })
            }
            return ''
        })
        }

        full.map((item) => (
          events.push({
            name: this.fullLanguage,
            start: new Date(item),
            end: new Date(item),
            color: 'red'
          })
        ))

        available.map((item) => {
          const name = item.startTime === '00:00' && item.endTime === '23:59' ? 'Allday' : `${item.startTime } - ${ item.endTime}`
          return events.push({
            name: `${name}`,
            // start: `${item.date} ${item.startTime}`,
            // end: `${item.date} ${item.endTime}`,
            start: new Date(item.date),
            end: new Date(item.date),
            color: 'blue'
          })
      })

        // available.map((item) => (
        //   events.push({
        //     name: this.$t('global.blank'),
        //     start: new Date(item),
        //     end: new Date(item),
        //     color: 'green'
        //   })
        // ))

        this.events = events
      },
      handleSelect (event) {
        if (!event.past) {
          this.$emit('input', event)
        }
      }
    }
  }
</script>
